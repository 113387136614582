
import { Vue, Component } from "vue-property-decorator";
import Intro from "@/components/feedback/performance/Intro.vue";
import Headline from "@/components/feedback/performance/Headline.vue";
import Examples from "@/components/feedback/performance/Examples.vue";
import IndivResults from "@/components/feedback/performance/IndivResults.vue";
import GroupResults from "@/components/feedback/performance/GroupResults.vue";
import Conclusion from "@/components/feedback/performance/Conclusion.vue";

import OneByOneComponentRenderer from "@/components/OneByOneComponentRenderer.vue";

@Component({
  name: "PerformanceFeedback",
  components: {
    OneByOneComponentRenderer,
  },
  emits: ["displayRedirect"],
})
export default class PerformanceFeedback extends Vue {
  feedbackComponents = [
    Intro,
    Headline,
    Examples,
    [IndivResults, GroupResults],
    Conclusion,
  ];
}
