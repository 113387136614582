
import { Vue, Component, Prop } from "vue-property-decorator";
import Chart from "chart.js/auto";
import { generateRandomString } from "@/utils";

@Component({})
export default class PieChart extends Vue {
  @Prop({ required: true })
  data!: number[];
  @Prop({ required: true })
  labels!: string[];
  @Prop({ required: false, type: Boolean, default: false })
  anonymized: boolean | undefined;

  mounted(): void {
    let chart = new Chart(document.getElementById(this.uniqueId), {
      type: "pie",
      data: this.chartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            // display: !this.anonymized,
            position: "bottom",
            onClick: (e: any, legendItem: any, legend: any) => {
              console.log(e, legendItem, legend);
            },
          },
          title: {
            display: true,
            text: "Schéma de la répartition des échanges",
          },
          tooltip: {
            callbacks: {
              title: () => "Pourcentage de participation",
              label: (context: {
                label: string;
                formattedValue: string | number;
              }) =>
                this.anonymized
                  ? ` ${context.formattedValue}%`
                  : ` ${context.label} : ${context.formattedValue}%`,
            },
          },
        },
      },
    });

    chart.resize(300, 300);
  }

  get fillingLabels(): string[] {
    let filling = [];
    for (let i = 1; i <= this.labels.length; i++) {
      filling.push(`Membre ${i}`);
    }
    return filling;
  }

  get chartData(): {
    labels: string[];
    datasets: { data: number[]; backgroundColor: string[] }[];
  } {
    return {
      labels: this.anonymized ? this.fillingLabels : this.labels,
      datasets: [
        {
          data: this.data,
          backgroundColor: [
            "#D4E157",
            "#EC407A",
            "#5C6BC0",
            "#66BB6A",
            "#29B6F6",
            "#AB47BC",
            "FFCA28",
            "#26A69A",
            "#9CCC65",
            "#FF7043",
            "#7E57C2",
            "#FFEE58",
            "#42A5F5",
            "#EF5350",
            "#26C6DA",
          ],
        },
      ],
    };
  }

  get uniqueId(): string {
    return "pie-chart" + generateRandomString(20);
  }
}
