
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { mapGetters, mapState } from "vuex";
import { FeedbackType } from "@/schemas/Enums";

@Component({
  name: "IndivResults",
  components: { Score },
  computed: {
    ...mapGetters("session", ["getIndividualScores", "getGroupScore"]),
    ...mapState("session", ["session"]),
  },
})
export default class IndivResults extends Vue {
  get displayGroupScore(): boolean {
    if (this.session.setting) {
      let settings = this.session.setting.feedback_details.find(
        (setting: {
          type: FeedbackType;
          display_feedback_message: boolean;
          display_score?: boolean;
          display_indiv_scores?: boolean;
          display_group_score?: boolean;
        }) => setting.type === FeedbackType.Performance
      );
      return settings ? settings.display_group_score : false;
    }
    return false;
  }

  get displayIndividualScore(): boolean {
    if (this.session.setting) {
      let settings = this.session.setting.feedback_details.find(
        (setting: {
          type: FeedbackType;
          display_feedback_message: boolean;
          display_score?: boolean;
          display_indiv_scores?: boolean;
          display_group_score?: boolean;
        }) => setting.type === FeedbackType.Performance
      );
      return settings ? settings.display_indiv_scores : false;
    }
    return false;
  }
}
