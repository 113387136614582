
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { mapActions } from "vuex";

@Component({
  components: { Score },
  methods: {
    ...mapActions("session", ["getSessionParticipation"]),
  },
})
export default class Conclusion extends Vue {
  fullyLoaded = false;
  participation = [];

  message1 =
    "La communication au sein de votre groupe a été équitable entre tous ces membres.";
  message2 =
    "La communication n'a pas été répartie de manière égale entre tous les membres de votre \n" +
    "groupe. ";

  created(): void {
    this.getSessionParticipation({
      sessionId: this.$route.params.sessionId,
      next: (participation) => {
        this.participation = participation.map((part) => part.participation);
        this.fullyLoaded = true;
      },
    });
  }

  get partProp(): number[] {
    let sum = 0;
    this.participation.forEach((num) => {
      sum += num;
    });
    return [...this.participation.map((part) => (part * 100) / sum)];
  }

  get message(): string {
    let nbPlayers = this.participation.length;
    let optimalRepartition = 100 / nbPlayers;
    let max = optimalRepartition + (1 + 1 / nbPlayers) * 5;
    let min = optimalRepartition - (1 + 1 / nbPlayers) * 5;
    if (this.partProp.every((part) => part <= max && part >= min)) {
      return this.message1;
    }
    return this.message2;
  }
}
